<template>
  <async-container :loading="!loaded" style="max-width: 900px">
    <div v-if="item">
      <!-- Imagens de capa, avatar e menu de opções -->
      <v-card class="overflow-hidden">
        <v-img :src="capaSrc" width="100%" min-height="192" max-height="380" class="align-end overflow-visible">
          <v-app-bar style="position: absolute; top: 0" flat color="rgba(0, 0, 0, 0)" class="d-block d-sm-none">
            <v-spacer></v-spacer>
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="white" fab small v-on="{ ...menu, ...tooltip }" v-bind="attrs">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <template v-if="souEu">
                  <v-list-item @click="$refs['img-uploader'].click()">
                    <v-list-item-content>
                      <v-list-item-title class="option-item">
                        <v-icon class="mr-1">mdi-account-circle</v-icon>Alterar foto
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="inTodo">
                    <v-list-item-content>
                      <v-list-item-title class="option-item">
                        <v-icon class="mr-1">mdi-image-area</v-icon>Alterar capa
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="dialogSenha = true">
                    <v-list-item-content>
                      <v-list-item-title class="option-item">
                        <v-icon class="mr-1">mdi-lock-reset</v-icon>Alterar senha
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item @click="dialogPermissoes = true">
                  <v-list-item-content>
                    <v-list-item-title class="option-item">
                      <v-icon class="mr-1">mdi-shield-lock</v-icon>Permissões
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>
          <div class="d-flex flex-column flex-sm-row align-center text-center text-sm-left pl-0 pl-sm-3 mb-3 mb-sm-n6" style="width: 100%">
            <v-avatar size="96" class="elevation-3">
              <img :src="avatarSrc" alt="">
            </v-avatar>
            <div class="d-flex flex-column ml-sm-4">
              <p class="name title">{{item.apelido ? item.apelido : item.nome}}</p>
              <p class="name subtitle-1 text-capitalize" v-if="item.cargo">{{item.cargo.toLowerCase()}}</p>
            </div>
          </div>
        </v-img>
        <v-card-actions class="d-none d-sm-flex">
          <v-spacer style="max-width: calc(96px + .75rem)"></v-spacer>
          <template v-if="souEu">
            <v-btn text x-small color="blue darken-4" @click="() => {estouAlterandoCapa = false; $refs['img-uploader'].click()}">
              <v-icon class="mr-1">mdi-account-circle</v-icon>Alterar foto
            </v-btn>
            <v-btn text x-small color="blue darken-4" @click="() => {estouAlterandoCapa = true; $refs['img-uploader'].click()}">
              <v-icon class="mr-1">mdi-image-area</v-icon>Alterar capa
            </v-btn>
            <v-btn text x-small color="blue darken-4" @click="dialogSenha = true">
              <v-icon class="mr-1">mdi-lock-reset</v-icon>Alterar senha
            </v-btn>
          </template>
          <v-btn text x-small color="blue darken-4" @click="dialogPermissoes = true">
            <v-icon class="mr-1">mdi-shield-lock</v-icon>Permissões
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Dados pessoais -->
      <v-card class="mt-5">
        <v-card-title class="title-bar justify-space-between flex-column flex-sm-row">
          Dados cadastrais
          <v-btn color="success" depressed v-if="item.ativo" @click="desativarUsuario" :loading="inativandoUsuario">ATIVO</v-btn>
          <v-btn color="error" depressed v-else @click="reativarUsuario" :loading="inativandoUsuario">INATIVO</v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form-dados">
            <v-text-field
              label="Nome completo"
              v-model="item.nome"
              :readonly="!souEu && !isAdm"
              :rules="[rules.obrigatorio()]"
            ></v-text-field>
            <v-text-field
              label="Nome de exibição"
              v-model="item.apelido"
              placeholder="Não utiliza"
              :readonly="!souEu && !isAdm"
              hint="Este é o nome que será exibido aos outros"
            ></v-text-field>
            <v-text-field
              label="Função"
              v-model="item.cargo"
              placeholder="Não informado"
              :readonly="!souEu && !isAdm"
            ></v-text-field>
            <v-text-field
              label="E-mail operacional"
              v-model="item.email"
              placeholder="Não informado"
              :readonly="!souEu && !isAdm"
              :rules="[rules.obrigatorio(), rules.email]"
            ></v-text-field>
            <v-select
              label="Sexo"
              :items="[
                {text: 'Indefinido', value: 0},
                {text: 'Masculino', value: 2},
                {text: 'Feminino', value: 1},
              ]"
              v-model="item.sexo"
              :rules="[rules.obrigatorio(false)]"
            ></v-select>
            <v-text-field
              v-if="souEu"
              label="CPF"
              v-model="item.cpf"
              placeholder="Não informado"
              readonly
              hint="Somente você pode ver o CPF"
              persistent-hint
            ></v-text-field>
          </v-form>
          <p class="caption mt-3 mb-0">Usuário desde</p>
          <p class="mb-0">{{formatarData(item.data_criacao).substr(0, 10)}}</p>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="souEu || isAdm">
          <v-btn color="primary" :loading="savingUser" @click="updateUsuario">Salvar alterações</v-btn>
        </v-card-actions>
      </v-card>
      <!-- Dialog: Painel de permissões -->
      <v-dialog v-model="dialogPermissoes" width="500" max-width="94%">
        <v-card>
          <v-card-title class="title-bar">
            Permissões
            <v-spacer></v-spacer>
            <v-icon @click="dialogPermissoes = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-alert type="warning" v-if="!isAdm" dense>Sem permissão para alterar estes controles</v-alert>
            <v-switch
              v-for="(p, index) in permissoes"
              :key="p.id"
              v-model="permissoes[index].status"
              :label="p.titulo"
              :hint="p.descricao"
              persistent-hint
              @change="alterarPermissao(p.id, $event)"
              :loading="p.loading"
              :disabled="disableControls || !isAdm"
              dense
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialog: Painel de troca de senha -->
      <v-dialog v-model="dialogSenha" width="500" max-width="94%">
        <v-card>
          <v-card-title class="title-bar">
            Alterar senha
            <v-spacer></v-spacer>
            <v-icon @click="dialogSenha = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-form ref="form-password" :disabled="savingPassword" @submit.prevent="alterarSenha">
              <v-text-field
                label="Senha atual"
                placeholder="Insira sua senha atual"
                prepend-inner-icon="mdi-lock"
                v-model="oldPassword"
                outlined
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                autocomplete="current-password"
                autocapitalize="off"
                dense
              ></v-text-field>
              <v-text-field
                label="Nova senha"
                placeholder="Escolha uma senha"
                prepend-inner-icon="mdi-lock"
                :rules="passwordRules"
                v-model="newPassword"
                outlined
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword2 ? 'text' : 'password'"
                @click:append="showPassword2 = !showPassword2"
                autocomplete="off"
                autocapitalize="off"
                dense
              ></v-text-field>
              <v-text-field
                label="Repita sua senha"
                placeholder="Repita sua senha"
                prepend-inner-icon="mdi-lock"
                :rules="[...passwordRules, (v) => v === newPassword || 'As senhas não coincidem']"
                v-model="newPassword2"
                outlined
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword2 ? 'text' : 'password'"
                @click:append="showPassword2 = !showPassword2"
                autocomplete="off"
                autocapitalize="off"
                dense
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialogSenha = false" :disabled="savingPassword">Cancelar</v-btn>
            <v-btn text color="primary" @click="alterarSenha" :loading="savingPassword">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog: Cropper do avatar -->
      <v-dialog v-model="dialogCropperAvatar" width="400" :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
        <v-card>
          <div class="d-flex">
            <div>
              <v-card-title>Ajuste a imagem</v-card-title>
              <v-card-subtitle>Arraste a imagem e regule o zoom</v-card-subtitle>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogCropperAvatar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <vue-cropper
              ref="cropper-avatar"
              :src="cropperAvatarSrc"
              :aspectRatio="estouAlterandoCapa ? (1366/635) : 1"
              :cropBoxMovable="true"
              :viewMode="1"
              :dragMode="'move'"
            ></vue-cropper>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="primary" @click="trocarAvatar" :loading="updatingAvatar">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Ocultos -->
      <input
        ref="img-uploader"
        style="display: none"
        type="file"
        accept="image/jpeg,image/png"
        @change="onImageUpload"
      >
    </div>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import UserWebClient from '@/http/UserWebClient';
import moment from 'moment';
import VueCropper from "vue-cropperjs";
import 'cropperjs/dist/cropper.css';
import ImageHelper from "@/helpers/ImageHelper";
export default {
  name: 'Usuario',
  components: {AsyncContainer, VueCropper},
  props: ['usuario'],
  data: () => ({
    webClient: new UserWebClient(),
    loaded: false,
    item: null, //Usuario
    capa: null, //Blob
    avatar: null, //Blob
    dialogPermissoes: false,
    dialogSenha: false,
    permissoes: [],
    disableControls: false,
    isAdm: false,
    savingUser: false,
    rules: {
      obrigatorio: (aceitarZero = true) => (v) => (!!v && (typeof v === 'string' || typeof v === 'boolean')) || (typeof v === 'number' && (!!v || (aceitarZero && v === 0))) || (Array.isArray(v) && v.length > 0) || 'Este campo é obrigatório. Faça o preenchimento.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido.'
      }
    },
    passwordRules: [
      v => !!v || 'Digite sua senha',
      v => (v && v.length >= 4) || 'Precisa ter pelo menos 4 caracteres',
      v => (v && v.length <= 16) || 'O limite é 16 caracteres',
      v => (!/\s/g.test(v)) || 'Espaços não são permitidos',
    ],
    newPassword: '',
    newPassword2: '',
    oldPassword: '',
    showPassword: false,
    showPassword2: false,
    savingPassword: false,
    iptCropperAvatarBlob: null,
    dialogCropperAvatar: false,
    updatingAvatar: false,
    estouAlterandoCapa: false,
    inativandoUsuario: false,
  }),
  methods: {
    async loadData() {
      this.loaded = false;
      try {
        const r = await Promise.all([
          this.webClient.getUsuario(this.usuario),
          this.webClient.getUsuarioCapa(this.usuario),
          this.webClient.getUsuarioAvatar(this.usuario),
          this.loadPermissoes(),
        ]);
        this.item = r[0];
        this.capa = r[1];
        this.avatar = r[2];
        if (this.souEu) {
          const perm = this.permissoes.find(p => p.id === 4);
          this.isAdm = perm.status === true;
        } else {
          const perms = await this.webClient.getPermissoes(this.$store.getters['session/payload'].user);
          this.isAdm = perms.find(p => p.id === 4).status === true;
        }
      } catch (e) {
        this.$router.back();
      } finally {
        this.loaded = true;
      }
    },
    async loadPermissoes() {
      const permissoesSistema = await this.webClient.getPermissoes();
      const permissoesUsuario = await this.webClient.getPermissoes(this.usuario);
      this.permissoes = permissoesSistema.map(i => {
        i.status = permissoesUsuario.find(p => p.id === i.id).status;
        i.loading = false;
        return i;
      });
    },
    async alterarPermissao(permissaoId, valor) {
      this.disableControls = true;
      const i = this.permissoes.findIndex(p => p.id === permissaoId);
      this.permissoes[i].loading = true;

      try {
        await this.webClient.updatePermissao(this.usuario, permissaoId, valor);
        this.permissoes[i].loading = false;
      } catch (e) {
        await this.loadPermissoes();
      } finally {
        this.disableControls = false;
      }
    },
    async updateUsuario() {
      if (!this.$refs['form-dados'].validate()) return;
      this.savingUser = true;
      try {
        await this.webClient.updateUsuario(this.usuario, this.item.cpf, this.item.nome, this.item.apelido, this.item.email, this.item.cargo, this.item.sexo)
      } finally {
        this.savingUser = false;
      }
    },
    async alterarSenha() {
      if (!this.$refs['form-password'].validate()) return;
      this.savingPassword = true;
      try {
        await this.webClient.updateSenha(this.newPassword, this.oldPassword);
        this.dialogSenha = false;
        this.$snackbar({text: 'Senha alterada', color: 'success'});
      } finally {
        this.savingPassword = false;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    inTodo() {
      alert('Esta funcionalidade está em desenvolvimento. Aguarde a próxima atualização');
    },
    onImageUpload(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      this.iptCropperAvatarBlob = new Blob([file], {type: file.type});
      if (this.$refs['cropper-avatar']) {
        this.$refs['cropper-avatar'].replace(this.cropperAvatarSrc);
        this.$refs['cropper-avatar'].setAspectRatio(this.estouAlterandoCapa ? (1366/635) : 1);
      }
      this.dialogCropperAvatar = true;
      this.$refs['img-uploader'].value = '';
    },
    async trocarAvatar() {
      const canvas = this.$refs['cropper-avatar'].getCroppedCanvas({
        width: this.estouAlterandoCapa ? 1366 : 192,
        height: this.estouAlterandoCapa ? 635 : 192,
        fillColor: '#ffffff',
      });
      const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .92);
      const blob = ImageHelper.dataURItoBlob(base64);
      this.updatingAvatar = true;
      try {
        if (this.estouAlterandoCapa) {
          await this.webClient.setUsuarioCapa(blob);
          this.capa = blob;
        }
        else {
          await this.webClient.setUsuarioAvatar(blob);
          this.avatar = blob;
        }
        this.dialogCropperAvatar = false;
      } finally {
        this.updatingAvatar = false;
      }
    },
    async desativarUsuario() {
      if (!window.confirm('Deseja desativar este usuário? Ele não poderá acessar o sistema. Esta ação pode ser desfeita.')) return;
      this.inativandoUsuario = true;
      try {
        await this.webClient.desativarUsuario(this.usuario);
        this.item.ativo = false;
      } finally {
        this.inativandoUsuario = false;
      }
    },
    async reativarUsuario() {
      if (!window.confirm('Deseja ativar este usuário? Ele voltará a acessar o sistema.')) return;
      this.inativandoUsuario = true;
      try {
        await this.webClient.reativarUsuario(this.usuario);
        this.item.ativo = true
      } finally {
        this.inativandoUsuario = false;
      }
    },
  },
  computed: {
    souEu() {
      return this.$store.getters['session/payload'].user === this.usuario;
    },
    capaSrc() {
      return this.capa ? URL.createObjectURL(this.capa) : '';
    },
    avatarSrc() {
      return this.avatar ? URL.createObjectURL(this.avatar) : '';
    },
    cropperAvatarSrc() {
      return this.iptCropperAvatarBlob ? window.URL.createObjectURL(this.iptCropperAvatarBlob) : null;
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    usuario() {
      this.loadData();
    }
  },
}
</script>

<style scoped>
  .name {
    color: white;
    margin-bottom: 0;
    text-shadow: rgba(0, 0, 0, 0.5) -1px -1px 0,
    rgba(0, 0, 0, 0.5) 1px -1px 0,
    rgba(0, 0, 0, 0.5) -1px 1px 0,
    rgba(0, 0, 0, 0.5) 1px 1px 0;
    line-height: 1.15rem;
  }
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem !important;
    margin-bottom: 1rem;
  }
</style>